import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useThree, extend, useFrame, useLoader, useUpdate } from 'react-three-fiber';
import * as THREE from 'three';
import { CustomControls } from './CustomControls';
import { Vector3, Euler } from 'three';
import { useDispatch, useSelector } from 'react-redux';
import assetApi from '../../apis/api/asset';
import { PAGES, LAYERS, OBJECT_TYPES, HOTSPOT_TYPES, WEBSOCKET_CHANNEL, ACTION_NAME } from '../../constants/options';
import { gsap } from 'gsap';
import { getMediaUrl } from '../../helper/media';
import amenityApi from '../../apis/api/amenities';
import { reqSetActiveAmenityArea, reqSetActiveAmenity } from '../../reduxs/amenities/action';
import { reqSetExploreModal, reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import { reqSetSelectedUnit } from '../../reduxs/unit-explore/action';
import { reqSetActiveGalleryId, reqSetActivePatourId, reqSetPage } from '../../reduxs/home/action';
import socket, { emitUIActionEvent } from '../../helper/socket';


extend({ CustomControls });

const CanvasBox = React.memo(React.forwardRef((props, refScene) => {
  const {
    _3dSetting,
    controls,
    fbxs,
    hotspots,
    isIntroduction,
    objects,
    isPreview,
    targetPosition,
    needReloadSelectedHotspotId,
    selectedHotspotId
  } = props;

  const dispatch = useDispatch();

  THREE.Cache.enabled = true;

  const light = useRef();
  let timeVector3 = new Vector3(0, 0, 0);

  let isCameraAnimation = false;

  let matrix = new THREE.Matrix4();
  let matrix1 = new THREE.Matrix4();
  let worldMatrix = new THREE.Matrix4();
  let position = new THREE.Vector3();
  let scale = new THREE.Vector3();
  let quaternion = new THREE.Quaternion();
  let rotation = new Euler(0, 0, 0, 'XYZ');

  let color = new THREE.Color('#ff00ff');

  const [isCameraAnimated, setCameraAnimated] = useState(false);

  let pointerDownId;
  let selectedInstance;
  let hotspotPointerDownId;

  let lastPosition = new THREE.Vector3();
  let lastTarget = new THREE.Vector3();

  const authUser = useSelector((state) => state.user.data);
  let meshInstanceMap = {};
  let mapObjectId = {};

  populateMeshInstanceMapKeys(fbxs)
  associateModelsToMap(objects)

  function getFbxFileName(fbx) {
    let name = fbx.name;
    name = name.split('.').slice(0, -1).join('.');
    // join any words with '_'
    name = name.split(' ').join('_');
    return name.toLowerCase();
  };

  function getModelFileName(model) {
    let name = model['3d_filename'];
    // remove file extension
    name = name.split('.').slice(0, -1).join('.');
    // join any words with '_'
    name = name.split(' ').join('_');
    return name.toLowerCase();
  }

  function populateMeshInstanceMapKeys(fbxs) {
    fbxs.forEach(fbx => {
      let entry = { model: fbx, instances: [] };
      let key = getFbxFileName(fbx);
      meshInstanceMap[key] = entry;
    });
  }

  function associateModelsToMap(objects) {
    objects.forEach(obj => {
      // Make assumption that we can remove .fbx from file_name
      mapObjectId[obj.id] = obj;
      let name = getModelFileName(obj);
      if (!meshInstanceMap[name]) {
        console.warn('No FBX File supplied for', obj)
        return
      }
      meshInstanceMap[name].instances.push(obj);
    });
  }

  function handleAreaClick(controls, camLookAtPosition, camPosition) {
    if (isPreview) {
      return;
    }
    return controls.current.lookAtAndMovePosition(camLookAtPosition, camPosition, () => {});
  }

  function updateHotspot() {
    let selectedId = controls.current?.selectedHotspotId ?? '';
    let hotspot3Ds = controls.current?.hotspot3Ds ?? [];
    for (let i = 0; i < hotspot3Ds.length; i++) {
      let hotspot3D = hotspot3Ds[i];
      if (!hotspot3D) {
        continue;
      }
      let hotspot = hotspot3D.userData;
      let isVisible = true;
      let isSubHotspot = hotspot.parent_id != null;
      if (isSubHotspot) {
        isVisible = hotspot.parent_id == selectedId;
      } else {
        isVisible = hotspot.id != selectedId;
      }
      hotspot3D.visible = isVisible;
      if (!isVisible) {
        hotspot3D.layers.set(LAYERS.DISABLE);
      } else {
        hotspot3D.layers.set(hotspot.layer);
      }
    }
  }

  function threePosition(data) {
    return new Vector3(data.x, data.z, -data.y);
  }

  function threePosition2(data, vector) {
    vector.x = data.x;
    vector.y = data.z;
    vector.z = -data.y;
  }

  // Color:
  const setSelectInstanceForce = (instance, status) => {
    if (controls.current == null) {
      return;
    }
    if (refScene.current == null) {
      return;
    }
    let cameraUserData = controls.current.object.userData;
    let meshInstanceMap = cameraUserData.meshInstanceMap;

    let name = getModelFileName(instance);
    let meshInstance = meshInstanceMap[name];
    if (meshInstance == null) {
      return null;
    }
    let index = meshInstance.instances.findIndex((e) => e.id === instance.id);
    if (index < 0) {
      return null;
    }
    let imesh = refScene.current.getObjectByName(name);
    if (imesh == null) {
      return null;
    }
    let userData = imesh.userData[instance.id];
    if (userData == null) {
      return null;
    }

    if (status == 0) {
      color.set(userData.color);
    } else if (status == 1) {
      color.set(userData.hover_color);
    } else if (status == 2) {
      color.set(userData.active_color);
    }

    imesh.userData[instance.id].isActive = status == 2;

    imesh.setColorAt(index, color);
    if (imesh.instanceColor != null) {
      imesh.instanceColor.needsUpdate = true;
    }
    return userData.light;
  };

  function setActiveObjectIds(ids) {
    if (controls.current == null) {
      return;
    }
    let userData = controls.current.object.userData;
    let prevIds = userData.selectedObjectIds.slice();
    handleResetObjectColor(prevIds);
    controls.current.object.userData.selectedObjectIds = ids;
    const lights = handleSetActiveObjectColor(ids);

    // if (!isPreview && LIFX_ADMIN.includes(authUser.id)) {
    //   lifxApi.turnOffAll().then(res => {
    //     console.log(res, 'turn off light');
    //     handleUpdateLightState(lights, 1);
    //   })
    // }
  }

  const handleResetObjectColor = (prevIds) => {
    if (controls.current == null) {
      return;
    }
    let userData = controls.current.object.userData;
    prevIds.forEach((id) => {
      let instance = userData.mapObjectId[id];
      if (instance != null) {
        setSelectInstanceForce(instance, 0);
      }
    });
  };

  const handleSetActiveObjectColor = (ids) => {
    const lights = [];

    if (refScene.current == null || controls.current == null) {
      return lights;
    }
    let userData = controls.current.object.userData;

    ids.forEach((id) => {
      let instance = userData.mapObjectId[id];
      if (instance != null) {
        let light = setSelectInstanceForce(instance, 2);
        light && lights.push(light);
      }
    });

    return lights;
  };

  // const handleUpdateLightState = (lights) => {
  //   const lightGroups = lights.reduce((prev, value) => {
  //     const { uuid, zone } = value;
  //     if (prev[uuid]) {
  //       prev[uuid].push(zone);
  //     } else {
  //       prev[uuid] = [zone];
  //     }
  //     return prev;
  //   }, {});

  //   Object.keys(lightGroups).length && lifxApi.setStates(lightGroups).then(res => console.log(res, 'turn on light'));
  // };

  const setSelectInstance = (instance, status) => {
    let name = getModelFileName(instance);
    let meshInstance = meshInstanceMap[name];
    if (meshInstance == null) {
      return;
    }
    if (refScene.current == null) {
      return;
    }
    let index = meshInstance.instances.findIndex((e) => e.id === instance.id);
    if (index < 0) {
      return;
    }
    let imesh = refScene.current.getObjectByName(name);
    if (imesh == null) {
      return;
    }
    let userData = imesh.userData[instance.id];
    if (userData == null) {
      return null;
    }

    if (userData?.isActive) {
      return;
    }
    if (status == 0) {
      color.set(userData.color);
    } else if (status == 1) {
      color.set(userData.hover_color);
    } else if (status == 2) {
      color.set(userData.active_color);
    }

    imesh.userData[instance.id].isActive = status == 2;

    imesh.setColorAt(index, color);
    if (imesh.instanceColor != null) {
      imesh.instanceColor.needsUpdate = true;
    }
  };
  // End color

  const handleClickHotspot = async (hotspot) => {

    if (!isPreview) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_HOTSPOT, hotspot);
    }

    if (![HOTSPOT_TYPES.AMENITY_ITEM].includes(hotspot.link_type)) {
      dispatch(reqSetActiveAmenityArea(''));
    }

    switch (hotspot.link_type) {
      case HOTSPOT_TYPES.GALLERY:
        dispatch(reqSetActiveGalleryId(hotspot.link));
        dispatch(reqSetPage(PAGES.GALLERY_PAGE));
        break;
      case HOTSPOT_TYPES.PANO_TOUR:
        dispatch(reqSetActivePatourId(hotspot.link));
        dispatch(reqSetPage(PAGES.IMMERSE_PAGE));
        break;
      case HOTSPOT_TYPES.EXCUTIVE_SUMMARY:
        dispatch(reqSetPage(PAGES.EXCUTIVE_SUMMARY_PAGE));
        break;
      case HOTSPOT_TYPES.AMENITY:
        dispatch(reqSetActiveAmenityArea(hotspot.link));
        dispatch(reqSetPage(PAGES.AMENITY_PAGE));
        break;
      case HOTSPOT_TYPES.UNIT_EXPLORE:
        dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
        break;
      case HOTSPOT_TYPES.MODAL:
        dispatch(reqSetPage(PAGES.NONE));
        dispatch(reqSetExploreModal(hotspot.link));
        dispatch(reqSetIsShowExploreModal(true));
        break;
      case HOTSPOT_TYPES.AMENITY_ITEM:
        dispatch(reqSetActiveAmenity(hotspot.link));
        const res = await amenityApi.getAmenityDetail(hotspot.link);
        dispatch(reqSetExploreModal(res?.data?.modal));
        dispatch(reqSetIsShowExploreModal(true));
        break;
      default:
        break;
    }

    if (hotspot.parent_id) {
      return;
    }

    controls.current.selectedHotspotId = hotspot.id;
    updateHotspot();

    if (hotspot.cam_position && hotspot.cam_focus_point_position && !isPreview) {
      controls.current.lookAtAndMovePosition(
        threePosition(hotspot.cam_focus_point_position),
        threePosition(hotspot.cam_position),
        () => {}
      );
    }
  };

  const Hotspot = React.memo((props) => {
    const onPointerOver = () => (controls.current && controls.current.setCursorStyle('pointer'));
    const onPointerOut = () => (controls.current && controls.current.setCursorStyle('grab'));
    const webglHotspots = hotspots.map((hotspot) => {
      hotspot.texture = useLoader(THREE.TextureLoader, getMediaUrl(hotspot.image_path));
      return hotspot;
    });

    let selectedHotspotId = controls.current?.selectedHotspotId ?? '';
    controls.current.hotspot3Ds = [];

    return (
      <group>
        {webglHotspots.map((hotspot, index) => {
          threePosition2(hotspot.position, position);
          let isVisible = true;
          let isSubHotspot = hotspot.parent_id != null;
          if (isSubHotspot) {
            isVisible = hotspot.parent_id == selectedHotspotId;
          } else {
            isVisible = hotspot.id != selectedHotspotId;
          }
          return (
            <sprite
              ref={(r) => {
                if (r && r.material) {
                  r.material.map.minFilter = THREE.LinearMipMapNearestFilter;
                  r.material.map.magFilter = THREE.LinearFilter;
                  r.material.precision = 'highp';
                  r.material.map.needsUpdate = true;
                }
                if (r != undefined && controls.current) {
                  controls.current.hotspot3Ds.push(r);
                }
              }}
              visible={isVisible}
              layers={isVisible ? hotspot.layer : LAYERS.DISABLE}
              onPointerOver={() => onPointerOver()}
              onPointerOut={() => onPointerOut()}
              userData={hotspot}
              onPointerDown={ () => {
                hotspotPointerDownId = hotspot.id;
               }}
              onPointerUp={ (e) => {
                hotspotPointerDownId == hotspot.id && handleClickHotspot(e?.object?.userData);
                hotspotPointerDownId = null;
              }}
              key={index}
              position={[position.x, position.y, position.z]}
              scale={[hotspot?.scale?.x || 1, hotspot?.scale?.y || 1, hotspot?.scale?.z || 1]}
            >
              <spriteMaterial sizeAttenuation={false} fog={false} precision='highp' attach="material" map={hotspot.texture} />
            </sprite>
          );
        })}
      </group>
    );
  });
  Hotspot.displayName = 'Hotspot';

  const RenderInstances = (instances, model) => {
    const {
      camera
    } = useThree();

    if (model.children.length <= 0 || instances.length <= 0) {
      return <group/>
    }
    let mesh_ = model.children[0];
    let firstInstance = instances[0];

    let castShadow = firstInstance.cast_shadow === 'true' || firstInstance.cast_shadow === true;
    let receiveShadow = firstInstance.receive_shadow === 'true' || firstInstance.receive_shadow === true;
    let use_texture = firstInstance.use_texture === true || firstInstance.use_texture === 'true';
    const isClickable = firstInstance.type == OBJECT_TYPES.UNIT || firstInstance.type == OBJECT_TYPES.AMENITY || firstInstance.type == OBJECT_TYPES.AREA;
    const selectedObjectIds = camera.userData.selectedObjectIds ?? [];

    if (light.current != null) {
      light.current.shadow.needsUpdate = true;
      light.current.layers.enableAll();
    }

    const ref = useUpdate((imesh) => {
      let iMeshUserData = {};
      instances.forEach((instance, index) => {
        scale.x = instance.scale.x;
        scale.y = instance.scale.y;
        scale.z = instance.scale.z;

        threePosition2(instance.xyz_position, position);

        rotation.x = (instance.rotation.x * Math.PI) / 180.0;
        rotation.y = (instance.rotation.z * Math.PI) / 180.0;
        rotation.z = (instance.rotation.y * Math.PI) / 180.0;

        quaternion.setFromEuler(rotation, true);

        matrix.compose(position, quaternion, scale);
        matrix1.compose(mesh_.position, mesh_.quaternion, mesh_.scale);

        worldMatrix.identity();
        worldMatrix.multiplyMatrices(matrix, matrix1);

        imesh.setMatrixAt(index, worldMatrix);

        if (mesh_?.material?.color != null && use_texture) {
          let hexString = mesh_.material.color.getHexString();
          Object.assign(instance, {color: `#${hexString}`});
        }

        const isActive = selectedInstance?.id === instance.id || selectedObjectIds.includes(instance.id);
        const userData = {
          alpha: instance.alpha != null ? instance.alpha / 100.0 : 1.0,
          hover_alpha: instance.hover_alpha != null ? instance.hover_alpha / 100.0 : 1,
          active_alpha: instance.active_alpha != null ? instance.active_alpha / 100.0 : 1.0,
          color: instance.color ?? '#999999',
          hover_color: instance.hover_color ?? instance.color,
          active_color: instance.active_color ?? instance.color,
          isActive: isActive,
          light: instance.light
        }

        iMeshUserData[instance.id] = userData;

        color.set(instance.color);
        imesh.setColorAt(index, color);
      });
      if (imesh != null && imesh.instanceMatrix != null) {
        imesh.instanceMatrix.needsUpdate = true;
      }
      if (imesh != null && imesh.instanceColor != null) {
        imesh.instanceColor.needsUpdate = true;
      }

      imesh.userData = iMeshUserData;

      if (receiveShadow) {
        imesh.updateMatrixWorld();
        if (light.current != null) {
          light.current.target = imesh;
        }
      }
    }, [instances]);

    const onPointerOver =
    isClickable ? (e) => {
      let instance = instances[e.instanceId];
      if (pointerDownId && pointerDownId != instance.id) {
          return;
      }
      if (instance.layer == LAYERS.VISIBLE_BUT_DISABLE) {
        return;
      }
      controls.current && controls.current.setCursorStyle('pointer');
      onPointerOverInstance(instance);
    } : null;

  const onPointerOut =
    isClickable ? (e) => {
      let instance = instances[e.instanceId];
      if (instance.layer == LAYERS.VISIBLE_BUT_DISABLE) {
        return;
      }
      if (pointerDownId && pointerDownId != instance.id) {
        return;
      }
      controls.current && controls.current.setCursorStyle('grab');
      onPointerOutInstance(instance);
    } : null;

    const onPointerDown = (e) => {
      e.stopPropagation();
      let instance = instances[e.instanceId];
      if (instance.layer == LAYERS.VISIBLE_BUT_DISABLE) {
        return;
      }
      pointerDownId = instance.id;
    };

    const onPointerUp = (e) => {
      e.stopPropagation();
      let instance = instances[e.instanceId];
      if (instance.layer == LAYERS.VISIBLE_BUT_DISABLE) {
        return;
      }
      pointerDownId == instance.id && onClick != null && onClick(e);
      pointerDownId = null;
    };

    const onClick = isClickable
      ? async (e) => {
        let instance = instances[e.instanceId];
        if (instance.layer == LAYERS.VISIBLE_BUT_DISABLE) {
          return;
        }

        onClickInstance(instance, selectedInstance);
      }
    : null;

    const key = getFbxFileName(model);

    let meshInstances =
    <instancedMesh
    ref={ref}
    args={[mesh_.geometry, mesh_.material, instances.length]}
    layers={firstInstance.layer}
    key={key}
    name={key}
    castShadow={castShadow}
    receiveShadow={receiveShadow}
    onPointerDown={onPointerDown}
    onPointerUp={onPointerUp}
    onPointerOver={onPointerOver}
    onPointerOut={onPointerOut}
     />
    return meshInstances;
  }

  function FbxModel() {
    if (!isIntroduction) {
      return <group />;
    }

    if (light.current != null) {
      light.current.layers.enableAll();
    }

    return (
      <group ref={refScene}>
         {Object.keys(meshInstanceMap).map((entry) => {
          const targetMap = meshInstanceMap[entry];
          if (!targetMap) {
            return;
          }
          const model = targetMap.model;
          const instances = targetMap.instances;

          return RenderInstances(instances, model);
      })}
      </group>
    );
  }

  const AnimationCamera = React.memo((props) => {
    const {animation3dSetting, controls} = props;
    const {
      camera
    } = useThree();

    const position = new THREE.Vector3();
    const lookAtPosition = animation3dSetting != null && animation3dSetting.cam_focus_position != null ?
     threePosition(animation3dSetting.cam_focus_position) :
     new THREE.Vector3(-102.89578369966134, -1.1178292546754195e-14, 131.5388245709879);
    const targetPosition = animation3dSetting != null && animation3dSetting.cam_position != null ?
    threePosition(animation3dSetting.cam_position) :
    new THREE.Vector3(-92.46747002504912, 260.2837561175679, 391.6135906913746);
    const delta = new Vector3(-200 - targetPosition.x, 270 - targetPosition.y, -630 - targetPosition.z);

    const pipeSpline = new THREE.CatmullRomCurve3([
      new THREE.Vector3(820 - delta.x, 810 - delta.y, 0 - delta.z),
      new THREE.Vector3(400 - delta.x, 790 - delta.y, -70 - delta.z),
      new THREE.Vector3(300 - delta.x, 445 - delta.y, -140 - delta.z),
      new THREE.Vector3(200 - delta.x, 330 - delta.y, -226 - delta.z),
      new THREE.Vector3(0 - delta.x, 285 - delta.y, -331 - delta.z),
      targetPosition,
    ]);

    setCameraAnimated(true);

    camera.position.copy(new THREE.Vector3(820 - delta.x, 810 - delta.y, 0 - delta.z));
    camera.lookAt(lookAtPosition);
    camera.updateProjectionMatrix();
    camera.layers.enableAll();
    camera.layers.disable(LAYERS.INVISIBLE_BUT_ENABLE);

    timeVector3.x = 0;
    timeVector3.y = 0;
    timeVector3.z = 0;

    gsap.to(timeVector3, {
      duration: 2,
      x: 1.0,
      y: 0.0,
      z: 0.0,
      onStart: function () {
        isCameraAnimation = true;
        controls.current.disabledUpdate = true;
      },
      onUpdate: function () {
        const x = timeVector3.x;
        pipeSpline.getPointAt(x, position);

        camera.position.copy(position);
        camera.lookAt(lookAtPosition);
        controls.current.didUpdate = true;
      },
      onComplete: function () {
        controls.current.disabledUpdate = false;
        controls.current.target = lookAtPosition;
        controls.current.saveState();
        dispatch(reqSetPage(PAGES.NONE));
        isCameraAnimation = false;
        controls.current.didUpdate = true;
      }
    });

    return <group />;
  });
  AnimationCamera.displayName = 'AnimationCamera';

  const CameraControls = React.memo(() => {
    const {
      camera,
      gl,
      raycaster,
    } = useThree();
    const domElement = gl.domElement;

    camera.userData.mapObjectId = mapObjectId;
    camera.userData.meshInstanceMap = meshInstanceMap;
    if (camera.userData.selectedObjectIds == null) {
      camera.userData.selectedObjectIds = [];
    }

    gl.info.autoReset = false;
    // Set max canvas resolution to 1080p without forcing container style updates

    let width;
    let height;
    let senderWindowSize = controls.current?.object?.userData?.senderWindowSize;
    if (!isPreview || senderWindowSize == null) {
      width = Math.min(window.innerWidth, 1280);
      height = Math.min(window.innerHeight, 720);
    } else {
      const scaleW = senderWindowSize.width / window.innerWidth;
      const scaleH = senderWindowSize.height / window.innerHeight;
      if (scaleH >= scaleW) {
        height = scaleH * window.innerHeight;
        width = senderWindowSize.width * height / senderWindowSize.height;
      } else {
        width = scaleW * window.innerWidth;
        height = senderWindowSize.height * width / senderWindowSize.width;
      }
    }

    if (!isPreview) {
      emitUIActionEvent(authUser, ACTION_NAME.WINDOW_SIZE, {
        width: width,
        height: height
      });
    }

    useThree().gl.setSize(
      width,
      height,
      false
    );

    useFrame(() => {
      if (controls.current != null && controls.current.needReloadSelectedHotspotId) {
        controls.current.selectedHotspotId = '';
        updateHotspot();
        controls.current.needReloadSelectedHotspotId = false;
      }
      if (!isPreview && controls.current.didUpdate) {
        lastPosition.x = controls.current.object.position.x;
          lastPosition.y = controls.current.object.position.y;
          lastPosition.z = controls.current.object.position.z;

          lastTarget.x = controls.current.target.x;
          lastTarget.y = controls.current.target.y;
          lastTarget.z = controls.current.target.z;
          //sendLocationSocket({position: lastPosition, lookAt: lastTarget});
          /*sendLocationSocket({
            matrix: controls.current.object.matrix.elements,
            projectionMatrix: controls.current.object.projectionMatrix.elements
           })*/

           let quaternion = {
             x: controls.current.object.quaternion.x,
             y: controls.current.object.quaternion.y,
             z: controls.current.object.quaternion.z,
             w: controls.current.object.quaternion.w,
           }
           sendLocationSocket({
            position: lastPosition,
            quaternion: quaternion,
            zoom: controls.current.object.zoom
           });
      }
      if (!isCameraAnimation && isCameraAnimated) {
        if (controls != null && controls.current != null) {
          controls.current.update();
        }
        return;
      }
    });

    let x = targetPosition.x;
    let y = targetPosition.y;
    let z = targetPosition.z;

    if (camera.targetPosition) {
      x = camera.targetPosition.x;
      y = camera.targetPosition.y;
      z = camera.targetPosition.z;
    }

    return (
      <customControls
        ref={controls}
        args={[camera, domElement]}
        disabledUpdate={isPreview || (isIntroduction && !isCameraAnimated)}
        neverUpdate={isPreview}
        autoRotate={false}
        enableDamping={true}
        maxDistance={3640}
        minDistance={2}
        minZoom={100}
        maxZoom={2000}
        light={light}
        raycaster={raycaster}
        //minPolarAngle={0}
        //maxPolarAngle={Math.PI / 2}
        rotateSpeed={0.2}
        target={[x, y, z]}
        needReloadSelectedHotspotId={needReloadSelectedHotspotId}
        selectedHotspotId={selectedHotspotId}
      />
    );
  });
  CameraControls.displayName = 'CameraControls';

  const sendLocationSocket = (content) => {
    socket.emit(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, {
      content: content,
      to: authUser.id,
      from: authUser.id,
    });
  };

  if (isPreview) {
    useEffect(() => {
      if (authUser) {
        socket.auth = {
          userId: authUser.id
        }
        socket.connect();

        socket.on(WEBSOCKET_CHANNEL.SHARE_3D_BUILDING_ACTION, ({ content }) => {
          if (content.action == 'onPointerOverInstance') {
            onPointerOverInstance(content.instance);
          } else if (content.action == 'onPointerOutInstance') {
            onPointerOutInstance(content.instance);
          } else if (content.action == 'onClickInstance') {
            onClickInstance(content.instance, content.selectedInstance);
          }
        });

        socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
          if (content.action === ACTION_NAME.CLICK_HOTSPOT) {
            handleClickHotspot(content.data);
          } else if (content.action === ACTION_NAME.WINDOW_SIZE) {
            if (controls.current != null) {
              controls.current.object.userData.senderWindowSize = content.data;
            }
          }
        });

        return () => {
          console.log('Socket disconnect');
          socket.disconnect();
        }
      }
    }, [authUser]);
  }

  const onPointerOverInstance = (instance) => {
    if (selectedInstance?.id != instance.id) {
      if (!isPreview) {
        socket.emit(WEBSOCKET_CHANNEL.SHARE_3D_BUILDING_ACTION, {
          content: {
            action: 'onPointerOverInstance',
            instance: instance
          },
          to: authUser.id,
          from: authUser.id,
        });
      }
      setSelectInstance(instance, 1);
    }
  };

  const onPointerOutInstance = (instance) => {
    if (selectedInstance?.id != instance.id) {
      if (!isPreview) {
        socket.emit(WEBSOCKET_CHANNEL.SHARE_3D_BUILDING_ACTION, {
          content: {
            action: 'onPointerOutInstance',
            instance: instance
          },
          to: authUser.id,
          from: authUser.id,
        });
      }
      setSelectInstance(instance, 0);
    }
  };

  const onClickInstance = async (instance, selectedInstance) => {
    if (!isPreview) {
      socket.emit(WEBSOCKET_CHANNEL.SHARE_3D_BUILDING_ACTION, {
        content: {
          action: 'onClickInstance',
          instance: instance,
          selectedInstance: selectedInstance
        },
        to: authUser.id,
        from: authUser.id,
      });
    }

    setActiveObjectIds([instance.id]);

    if (instance.cam_position) {
      const position = threePosition(instance.xyz_position);
      const camPosition = threePosition(instance.cam_position);
      const camLookAtPosition =
      instance.cam_focus_point_position != null
          ? threePosition(instance.cam_focus_point_position)
          : position;
      handleAreaClick(controls, camLookAtPosition, camPosition);
    }

    const asset = await assetApi.getAssetDetail(instance.id);
    if (asset && asset.data.amenity) {
      setPage(PAGES.AMENITY_PAGE);
      setAmenityActive(asset.data.amenity.id);
    }

    if (asset && asset.data.unit) {
      setActiveObjectIds([asset.data.id]);
      dispatch(reqSetSelectedUnit(asset.data.unit.id));
      controls.current?.hideLayer(LAYERS.HOTPOT);
      dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
    }
  };

  return (
    <>
      <Canvas
        gl={{
          outputEncoding: THREE.sRGBEncoding ,
          shadowMap: {
            autoUpdate : false,
            type : THREE.PCFSoftShadowMap,
          },
          logarithmicDepthBuffer : true,
          outputEncoding : THREE.sRGBEncoding,
        }}
        shadowMap
        pixelRatio={Math.max(window.devicePixelRatio, 2)}
        camera={{
          position: [
            1020 + _3dSetting.cam_position.x,
            540 + _3dSetting.cam_position.z,
            630 - _3dSetting.cam_position.y,
          ],
          fov: _3dSetting.FOV,
          near: 4,
          far: 3000,
        }}
      >
        {!isPreview && isIntroduction && !isCameraAnimated && <AnimationCamera animation3dSetting={_3dSetting} controls={controls} />}
        <CameraControls />
        <ambientLight intensity={0.2} color={0x2e2e2a} />
        {true && (
          <hemisphereLight
            intensity={0.4}
            skyColor={0xb1e1ff}
            groundColor={0x2e2e2a}
            position={[0, -10, 0]}
          />
        )}
        <directionalLight
          ref={light}
          intensity={1.6}
          castShadow
          color={0xffffff}
          position={[-1500, 600, 250]}
          shadow-mapSize-height={2048}
          shadow-mapSize-width={2048}
          shadow-camera-near={100}
          shadow-camera-far={4000}
          shadow-camera-left={-2000}
          shadow-camera-right={2000}
          shadow-camera-top={2000}
          shadow-camera-bottom={-2000}
          shadow-autoUpdate={false}
          shadow-bias={0.01}
          shadow-normalBias={2}
        />
        {true && <directionalLight intensity={0.7} color={0xffffff} position={[1500, 600, -250]} />}
        <React.Suspense fallback={null}>
          <FbxModel />
          <Hotspot selectedHotspotId={controls.current?.selectedHotspotId ?? ''} />
        </React.Suspense>
      </Canvas>
    </>
  );
}));

CanvasBox.displayName = 'CanvasBox';

export default CanvasBox;
